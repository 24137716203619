import { baseUrl } from '@/config/common'

export default {
  methods: {
    getThumbnailUrl (path, fileName) {
      return `${baseUrl}images/${path}thumbnails/${fileName}`
    },
    getFullImageUrl (path, fileName) {
      return `${baseUrl}images/${path}origin/${fileName}`
    }
  }
}